import SurveyWithVariants from "../data/SurveyWithVariants";
import Variant from "../data/Variant";

const convertVariants = (variants) => {
  return variants.map((variant) => {
    return new Variant({
      variantId: variant.variant_id,
      variantName: variant.variant_name,
      isActive: variant.is_active,
      wandbId: variant.wandb_id,
    });
  });
};

// Function to convert mock surveys to SurveyWithVariant objects
const convertIncomingData = (mockSurveys) => {
  return mockSurveys
    .map((mockSurvey) => {
      try {
        const {
          survey_id,
          survey_name,
          survey_title,
          source,
          variant_active_default,
          use_captions,
          source_config,
          variants,
          access,
          user_role,
        } = mockSurvey;

        const surveyVariants = convertVariants(variants);

        return new SurveyWithVariants({
          surveyId: survey_id,
          surveyName: survey_name,
          surveyTitle: survey_title,
          source: source,
          folder: source_config.source,
          apiKey: source_config.api_key,
          variantActiveByDefault: variant_active_default,
          useCaptions: use_captions,
          variants: surveyVariants,
          access: access || [],
          user_role: user_role,
        });
      } catch (error) {
        console.error(`Error converting survey data: ${error.message}`);
        return null;
      }
    })
    .filter((survey) => survey !== null); // Filter out any null surveys
};

export { convertIncomingData, convertVariants };
