const convertOutData = (survey) => {
  return {
    survey_id: Number(survey.surveyId),
    survey_name: survey.surveyName,
    survey_title: survey.surveyTitle,
    source: survey.source,
    variant_active_default: survey.variantActiveByDefault,
    use_captions: survey.useCaptions,
    source_config: {
      source: survey.folder,
      api_key: survey.apiKey,
    },
    variants: survey.variants.map((variant) => {
      return {
        variant_id: Number(variant.variantId),
        variant_name: variant.variantName,
        is_active: variant.isActive,
        wandb_id: variant.wandbId,
      };
    }),
    access: survey.access.map((accessItem) => {
      return {
        email: accessItem.email,
        role: accessItem.role,
      };
    }),
  };
};

export { convertOutData };
