import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  Spinner,
  Card,
  Alert,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";
import SurveyWithVariants from "../data/SurveyWithVariants";
import Variant from "../data/Variant";
import { authenticatedFetch } from "../helpFunctions/authFetch";
import { useNavigate } from "react-router-dom";
import { getEnvVariable } from "../helpFunctions/getEnvVariable";
import InfiniteScroll from "react-infinite-scroll-component";

const SurveyConfig = ({
  surveyData,
  handleSurveyChange,
  serviceAccountEmail,
}) => {
  const createMode = surveyData.surveyId === ""; // create or update view mode
  const apiBaseUrl = getEnvVariable("API_BASE_URL");

  const [surveyCopy, setSurveyCopy] = useState(
    new SurveyWithVariants(surveyData)
  );
  const [validated, setValidated] = useState(false);
  const [newAccess, setNewAccess] = useState({ email: "", role: "viewer" });
  const [isApiKeyValid, setIsApiKeyValid] = useState(
    surveyData.apiKey === "secured"
  ); //true: valid, false: invalid, null: on check
  const [projects, setProjects] = useState(
    surveyData.apiKey === "secured" ? [surveyData.folder] : []
  );
  const [variantSearch, setVariantSearch] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 50;
  const [searchTimeout, setSearchTimeout] = useState(null);

  const navigate = useNavigate();

  const handleVariantChange = (index) => {
    const updatedVariants = [...surveyCopy.variants];
    updatedVariants[index].isActive = !updatedVariants[index].isActive;
    setSurveyCopy({ ...surveyCopy, variants: updatedVariants });
  };

  const isValidGoogleCloudFolder = (url) => {
    // Regular expression to match Google Cloud Storage folder URL format gs:\/\/(?:[\w&&\-]+\/)+
    const googleCloudFolderRegex =
      /^gs:\/\/[a-zA-Z0-9-_]+(?:\/[a-zA-Z0-9-_]+)*\/?$/;
    return googleCloudFolderRegex.test(url);
  };

  const checkFoldersValid = () => {
    const folder = surveyCopy.folder.trim();
    const isValid = isValidGoogleCloudFolder(folder);
    return isValid;
  };

  // Handler for submitting the form
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setValidated(true);

    const form = e.currentTarget;
    if (
      form.checkValidity() &&
      ((surveyCopy.source === "GCS" && checkFoldersValid()) ||
        (surveyCopy.source === "W&B" && isApiKeyValid))
    ) {
      const activeVariants = surveyCopy.variants.filter(
        (variant) => variant.isActive
      );

      const mergedVariants = createMode
        ? activeVariants
        : surveyData.variants.map((existingVariant) => {
            const matchingVariant = surveyCopy.variants.find(
              (variant) => variant.variantName === existingVariant.variantName
            );
            return {
              ...existingVariant,
              isActive: matchingVariant
                ? matchingVariant.isActive
                : existingVariant.isActive,
            };
          });

      handleSurveyChange({
        ...surveyCopy,
        variants: mergedVariants,
        user_role: "manager",
      });
      console.log("Form submitted:", {
        ...surveyCopy,
        variants: mergedVariants,
      });
    }
  };

  const deleteSurvey = () => {
    handleSurveyChange(surveyCopy.surveyId);
    console.log("Survey delete");
  };

  const convertVariantsByNames = (variants) => {
    return variants.map((variant) => {
      return new Variant({
        variantId: 0,
        variantName: variant.name,
        isActive: surveyCopy.variantActiveByDefault,
        wandbId: variant.id,
      });
    });
  };

  const addAccess = () => {
    if (newAccess.email && newAccess.role) {
      const updatedSurveyCopy = new SurveyWithVariants(surveyCopy);
      updatedSurveyCopy.addAccess({
        email: newAccess.email,
        role: newAccess.role,
      });
      setSurveyCopy(updatedSurveyCopy);
      setNewAccess({ email: "", role: "viewer" });
    }
  };

  const removeAccess = (accessId) => {
    const updatedAccess = surveyCopy.access.filter(
      (item) => item.accessId !== accessId
    );
    setSurveyCopy({ ...surveyCopy, access: updatedAccess });
  };

  useEffect(() => {
    if (surveyCopy.apiKey.length === 40) {
      setIsApiKeyValid(true);
    }
  }, [surveyCopy.apiKey.length]);

  const validateApiKey = async (apiKey, surveyId) => {
    if (apiKey.length !== 40 && apiKey !== "secured") {
      setIsApiKeyValid(false);
      return;
    }
    try {
      setIsApiKeyValid(null);
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}validateApiKey?survey_id=${Number(surveyId) || 0}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiKey),
        }
      );
      if (response.ok) {
        const responseJson = await response.json();
        setIsApiKeyValid(true);
        setProjects(responseJson.projects);
      } else {
        setIsApiKeyValid(false);
      }
    } catch (error) {
      console.error("Error validating API key:", error);
      setIsApiKeyValid(false);
      alert("An error occurred while validating the API key");
    }
  };

  const handleProjectChange = (e) => {
    setSurveyCopy({ ...surveyCopy, folder: e.target.value });
    // getVariantsList(e.target.value, surveyCopy.apiKey, surveyCopy.source);
  };

  const loadVariants = async (searchTerm = "", pageNum = 1) => {
    if (
      !(surveyCopy.folder && (surveyCopy.source === "GCS" || surveyCopy.apiKey))
    ) {
      return;
    }

    if (loading) return;

    setLoading(true);
    try {
      const encodedSourceType = encodeURIComponent(surveyCopy.source);
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}variants?folder=${surveyCopy.folder}&survey_id=${
          Number(surveyCopy.surveyId) || 0
        }&source=${encodedSourceType}&search=${searchTerm}&page=${pageNum}&per_page=${ITEMS_PER_PAGE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(surveyCopy.apiKey),
        }
      );

      const responseJson = await response.json();
      if (response.ok) {
        const newVariants = responseJson.variants;

        // The following logic ensures that we do not remove old variants because they have the 'isActive' property that the user chose.
        // We also cannot just add the new variants and ignore duplicate variants.
        // Therefore, we filter and add only the variants that do not already exist.
        const variantsToAdd = newVariants.filter(
          (newVariant) =>
            !surveyCopy.variants.some(
              (existingVariant) =>
                existingVariant.variantName === newVariant.name
            )
        );
        const filteredOldVariants = surveyCopy.variants.filter(
          (oldVariant) =>
            !newVariants.some(
              (newVariant) => oldVariant.variantName === newVariant.name
            )
        );
        const variants = convertVariantsByNames(variantsToAdd);
        const existingVariants = surveyCopy.variants.filter((variant) =>
          newVariants.some(
            (newVariant) => variant.variantName === newVariant.name
          )
        );
        const combVariants = surveyCopy.variants[0]?.variantName
          ? [...variants, ...existingVariants, ...filteredOldVariants]
          : variants;
        setSurveyCopy({ ...surveyCopy, variants: combVariants });

        setHasMore(responseJson.hasMore);
      } else {
        console.log("🚀 ~ loadVariants ~ responseJson:", responseJson);
        alert(
          `An error occurred while loading variants: ${
            responseJson?.detail || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error loading variants:", error);
      alert("An error occurred while loading variants");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (searchTerm) => {
    setVariantSearch(searchTerm);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newTimeout = setTimeout(() => {
      setPage(1);
      loadVariants(searchTerm, 1);
    }, 300);

    setSearchTimeout(newTimeout);
  };

  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  const handleGetVariants = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setVariantSearch("");
    setPage(1);
    loadVariants("", 1);
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    loadVariants(variantSearch, nextPage);
  };

  return (
    <Container fluid className="px-3 py-2">
      <Card className="shadow-sm">
        <Card.Body className="p-3">
          <h4 className="mb-3 text-muted">
            {createMode ? "Create New Survey" : "Edit Survey"}
          </h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="compact-form"
          >
            {/* Survey Name */}
            <Form.Group controlId="surveyName" className="mb-2">
              <Form.Label className="mb-1">
                <small>Survey Name:</small>
              </Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value={surveyCopy.surveyName}
                onChange={(e) =>
                  setSurveyCopy({ ...surveyCopy, surveyName: e.target.value })
                }
                required
                placeholder="Enter survey name"
              />
              <Form.Control.Feedback type="invalid">
                Survey name is required.
              </Form.Control.Feedback>
            </Form.Group>

            {/* Survey Title */}
            <Form.Group controlId="surveyTitle" className="mb-2">
              <Form.Label className="mb-1">
                <small>Survey Title:</small>
              </Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value={surveyCopy.surveyTitle}
                onChange={(e) =>
                  setSurveyCopy({ ...surveyCopy, surveyTitle: e.target.value })
                }
                placeholder="Which is better?"
              />
            </Form.Group>

            {/* Source Selection */}
            <Form.Group controlId="sourceSelect" className="mb-2">
              <Form.Label className="mb-1">
                <small>Source:</small>
              </Form.Label>
              <Form.Select
                size="sm"
                value={surveyCopy.source}
                onChange={(e) => {
                  setSurveyCopy({
                    ...surveyCopy,
                    folder: "",
                    variantActiveByDefault: false,
                    variants: [],
                    source: e.target.value,
                  });
                }}
                required
              >
                <option value="">Select a source</option>
                <option value="W&B">Weights & Biases</option>
                <option value="GCS">Google Cloud Storage</option>
              </Form.Select>
            </Form.Group>

            {/* API Key (for W&B) */}
            {surveyCopy.source !== "GCS" && (
              <Form.Group controlId="apiKey" className="mb-2">
                <Form.Label className="mb-1">
                  <small>API Key:</small>
                </Form.Label>
                <Form.Control
                  type="password"
                  size="sm"
                  value={surveyCopy.apiKey}
                  onChange={(e) => {
                    setSurveyCopy({ ...surveyCopy, apiKey: e.target.value });
                    if (e.target.value.length === 40) {
                      setIsApiKeyValid(true);
                    }
                  }}
                  onBlur={(e) =>
                    validateApiKey(e.target.value, surveyCopy.surveyId)
                  }
                  required
                  placeholder={
                    createMode || surveyData.source !== "W&B"
                      ? "Enter API Key"
                      : "Update API Key"
                  }
                />
                <Form.Text className="text-muted">
                  {surveyCopy.apiKey !== "secured"
                    ? "Required for access to W&B API."
                    : "Enter API key only if you want update it."}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  API Key is required.
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Project Selection (for W&B) */}
            {surveyCopy.source === "W&B" && (
              <Form.Group controlId="projectSelect" className="mb-2">
                <Form.Label className="mb-1">
                  <small>Project:</small>
                </Form.Label>
                <Form.Select
                  size="sm"
                  value={surveyCopy.folder}
                  onChange={(e) => handleProjectChange(e)}
                  disabled={!isApiKeyValid}
                >
                  {isApiKeyValid === null && (
                    <option>Loading projects...</option>
                  )}
                  {isApiKeyValid === false && (
                    <option>Enter valid API key</option>
                  )}
                  {isApiKeyValid &&
                    surveyCopy.folder.length &&
                    projects.length === 0 && <option>No projects found</option>}
                  {isApiKeyValid &&
                    !surveyCopy.folder &&
                    projects.length > 0 && (
                      <option value="">Select project</option>
                    )}
                  {isApiKeyValid &&
                    projects.length > 0 &&
                    projects.map((project) => (
                      <option key={project} value={project}>
                        {project}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Select project.
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Folder Path (for GCS) */}
            {surveyCopy.source === "GCS" && (
              <Form.Group controlId="foldersSelect" className="mb-2">
                <Form.Label className="mb-1">
                  <small>Folder Path:</small>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="folder-path-tooltip">
                        Enter valid gsutil URI. Folder must contain variant
                        sub-folders with matching file names across variants.
                      </Tooltip>
                    }
                  >
                    <span className="ms-1 text-primary">ⓘ</span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  required
                  pattern={"gs://(?:[w&&-]+/)+"}
                  isInvalid={!checkFoldersValid() && validated}
                  value={surveyCopy.folder}
                  onChange={(e) =>
                    setSurveyCopy({ ...surveyCopy, folder: e.target.value })
                  }
                  placeholder="gs://bucket_name/path_to_folder"
                />
                <Form.Text className="text-muted">
                  Make sure to grant the 'Cloud Storage Admin' role to the
                  service account: "{serviceAccountEmail}
                  ".
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Enter valid gsutil URI
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Get Variants List Button */}
            <Button
              className="mb-2"
              variant="outline-primary"
              size="sm"
              type="button"
              onClick={handleGetVariants}
              disabled={
                !(
                  surveyCopy.folder &&
                  (surveyCopy.source === "GCS" || surveyCopy.apiKey)
                ) || loading
              }
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ms-2">Loading...</span>
                </>
              ) : (
                "Get Variants List"
              )}
            </Button>

            {/* Variant Active by Default Switch */}
            <Form.Group controlId="activeDefault" className="mb-2">
              <Form.Check
                type="switch"
                id="variantActiveByDefault"
                label={
                  <small>
                    {surveyCopy.source === "GCS"
                      ? "Variant active by default"
                      : "Run is added by default"}
                  </small>
                }
                checked={surveyCopy.variantActiveByDefault}
                onChange={(e) =>
                  setSurveyCopy({
                    ...surveyCopy,
                    variantActiveByDefault: e.target.checked,
                  })
                }
              />
            </Form.Group>

            {/* Using Captions or not */}
            <Form.Group controlId="useCaptionsControl" className="mb-2">
              <Form.Check
                type="switch"
                id="useCaptions"
                label={
                  <small>
                     Use captions if exist
                  </small>
                }
                checked={surveyCopy.useCaptions}
                onChange={(e) =>
                  setSurveyCopy({
                    ...surveyCopy,
                    useCaptions: e.target.checked,
                  })
                }
              />
            </Form.Group>

            {/* Manage Variants */}
            <Form.Group controlId="variantsManagement" className="mb-2">
              <Form.Label className="mb-1">
                <small>Manage Variants:</small>
              </Form.Label>
              {!surveyCopy.variants.length ? (
                <Alert variant="info" className="py-1 px-2">
                  <small>There are no variants.</small>
                </Alert>
              ) : (
                surveyCopy.variants[0].variantName && (
                  <>
                    {/* Search Input */}
                    {surveyCopy.variants.length > 0 && (
                      <InputGroup size="sm" className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Search variants..."
                          value={variantSearch}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        {variantSearch && (
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              if (searchTimeout) {
                                clearTimeout(searchTimeout);
                              }
                              handleSearch("");
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      </InputGroup>
                    )}
                    <div
                      id="variantsContainer"
                      className="border rounded p-2"
                      style={{ maxHeight: "200px", overflowY: "auto" }}
                    >
                      <InfiniteScroll
                        dataLength={surveyCopy.variants.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={
                          <small className="text-muted">Loading...</small>
                        }
                        scrollableTarget="variantsContainer"
                      >
                        {surveyCopy.variants.map(
                          (variant, index) =>
                            variant.variantName && (
                              <Form.Check
                                key={`${variant.variantName}-${index}`}
                                type="switch"
                                id={`variant-${index}`}
                                label={<small>{variant.variantName}</small>}
                                checked={variant.isActive}
                                onChange={() => handleVariantChange(index)}
                                className="mb-1"
                              />
                            )
                        )}
                      </InfiniteScroll>
                      {!loading && surveyCopy.variants.length === 0 && (
                        <small className="text-muted">
                          No matching variants found.
                        </small>
                      )}
                    </div>
                  </>
                )
              )}
            </Form.Group>

            {/* Manage Access */}
            <Form.Group controlId="accessManagement" className="mb-2">
              <Form.Label className="mb-1">
                <small>Manage Access:</small>
              </Form.Label>
              <ListGroup className="mb-2 border rounded">
                {!surveyCopy.access.length ? (
                  <ListGroup.Item className="py-1 px-2">
                    <small>No access entries</small>
                  </ListGroup.Item>
                ) : (
                  surveyCopy.access.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      className="py-1 px-2 d-flex justify-content-between align-items-center"
                    >
                      <small>
                        {item.email || item.groupEmail || item.userId} -{" "}
                        {item.role}
                      </small>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="py-0 px-1"
                        onClick={() => removeAccess(item.accessId)}
                      >
                        <small>Remove</small>
                      </Button>
                    </ListGroup.Item>
                  ))
                )}
              </ListGroup>
              <Row className="g-1">
                <Col sm={6}>
                  <Form.Control
                    size="sm"
                    type="email"
                    placeholder="Enter email"
                    value={newAccess.email}
                    onChange={(e) =>
                      setNewAccess({ ...newAccess, email: e.target.value })
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Form.Select
                    size="sm"
                    value={newAccess.role}
                    onChange={(e) =>
                      setNewAccess({ ...newAccess, role: e.target.value })
                    }
                  >
                    <option value="viewer">Viewer</option>
                    <option value="voter">Voter</option>
                    <option value="manager">Manager</option>
                  </Form.Select>
                </Col>
                <Col sm={2}>
                  <Button
                    variant="outline-success"
                    size="sm"
                    className="w-100"
                    onClick={addAccess}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            {/* Submit and Delete Buttons */}
            <div className="d-flex justify-content-between mt-3">
              <Button variant="primary" size="sm" type="submit">
                {createMode ? "Create Survey" : "Update Survey"}
              </Button>
              {!createMode && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  type="button"
                  onClick={deleteSurvey}
                >
                  Delete Survey
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SurveyConfig;
